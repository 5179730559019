export const Cell = () => import('../../components/Cell.vue' /* webpackChunkName: "components/cell" */).then(c => wrapFunctional(c.default || c))
export const HintIcon = () => import('../../components/HintIcon.vue' /* webpackChunkName: "components/hint-icon" */).then(c => wrapFunctional(c.default || c))
export const Language = () => import('../../components/Language.vue' /* webpackChunkName: "components/language" */).then(c => wrapFunctional(c.default || c))
export const NavMenu = () => import('../../components/NavMenu.vue' /* webpackChunkName: "components/nav-menu" */).then(c => wrapFunctional(c.default || c))
export const NodeIcon = () => import('../../components/NodeIcon.vue' /* webpackChunkName: "components/node-icon" */).then(c => wrapFunctional(c.default || c))
export const Notification = () => import('../../components/Notification.vue' /* webpackChunkName: "components/notification" */).then(c => wrapFunctional(c.default || c))
export const ProfileMenu = () => import('../../components/ProfileMenu.vue' /* webpackChunkName: "components/profile-menu" */).then(c => wrapFunctional(c.default || c))
export const Union = () => import('../../components/Union.vue' /* webpackChunkName: "components/union" */).then(c => wrapFunctional(c.default || c))
export const Welcome = () => import('../../components/Welcome.vue' /* webpackChunkName: "components/welcome" */).then(c => wrapFunctional(c.default || c))
export const AnimationSmartCity = () => import('../../components/Animation/SmartCity.vue' /* webpackChunkName: "components/animation-smart-city" */).then(c => wrapFunctional(c.default || c))
export const CardAccess = () => import('../../components/Card/Access.vue' /* webpackChunkName: "components/card-access" */).then(c => wrapFunctional(c.default || c))
export const CardButton = () => import('../../components/Card/Button.vue' /* webpackChunkName: "components/card-button" */).then(c => wrapFunctional(c.default || c))
export const CardChildren = () => import('../../components/Card/Children.vue' /* webpackChunkName: "components/card-children" */).then(c => wrapFunctional(c.default || c))
export const CardErrors = () => import('../../components/Card/Errors.vue' /* webpackChunkName: "components/card-errors" */).then(c => wrapFunctional(c.default || c))
export const CardProperties = () => import('../../components/Card/Properties.vue' /* webpackChunkName: "components/card-properties" */).then(c => wrapFunctional(c.default || c))
export const CardSheet = () => import('../../components/Card/Sheet.vue' /* webpackChunkName: "components/card-sheet" */).then(c => wrapFunctional(c.default || c))
export const CardTable = () => import('../../components/Card/Table.vue' /* webpackChunkName: "components/card-table" */).then(c => wrapFunctional(c.default || c))
export const CardTitle = () => import('../../components/Card/Title.vue' /* webpackChunkName: "components/card-title" */).then(c => wrapFunctional(c.default || c))
export const DialogAddLine = () => import('../../components/Dialog/AddLine.vue' /* webpackChunkName: "components/dialog-add-line" */).then(c => wrapFunctional(c.default || c))
export const DialogButton = () => import('../../components/Dialog/Button.vue' /* webpackChunkName: "components/dialog-button" */).then(c => wrapFunctional(c.default || c))
export const DialogConfirm = () => import('../../components/Dialog/Confirm.vue' /* webpackChunkName: "components/dialog-confirm" */).then(c => wrapFunctional(c.default || c))
export const DialogDelAccess = () => import('../../components/Dialog/DelAccess.vue' /* webpackChunkName: "components/dialog-del-access" */).then(c => wrapFunctional(c.default || c))
export const DialogDelDevice = () => import('../../components/Dialog/DelDevice.vue' /* webpackChunkName: "components/dialog-del-device" */).then(c => wrapFunctional(c.default || c))
export const DialogDelLine = () => import('../../components/Dialog/DelLine.vue' /* webpackChunkName: "components/dialog-del-line" */).then(c => wrapFunctional(c.default || c))
export const DialogDelNode = () => import('../../components/Dialog/DelNode.vue' /* webpackChunkName: "components/dialog-del-node" */).then(c => wrapFunctional(c.default || c))
export const DialogEdit = () => import('../../components/Dialog/Edit.vue' /* webpackChunkName: "components/dialog-edit" */).then(c => wrapFunctional(c.default || c))
export const DialogEditDevice = () => import('../../components/Dialog/EditDevice.vue' /* webpackChunkName: "components/dialog-edit-device" */).then(c => wrapFunctional(c.default || c))
export const DialogEditLine = () => import('../../components/Dialog/EditLine.vue' /* webpackChunkName: "components/dialog-edit-line" */).then(c => wrapFunctional(c.default || c))
export const DialogEditNode = () => import('../../components/Dialog/EditNode.vue' /* webpackChunkName: "components/dialog-edit-node" */).then(c => wrapFunctional(c.default || c))
export const DialogQrcode = () => import('../../components/Dialog/Qrcode.vue' /* webpackChunkName: "components/dialog-qrcode" */).then(c => wrapFunctional(c.default || c))
export const DialogShareNode = () => import('../../components/Dialog/ShareNode.vue' /* webpackChunkName: "components/dialog-share-node" */).then(c => wrapFunctional(c.default || c))
export const DialogValueLine = () => import('../../components/Dialog/ValueLine.vue' /* webpackChunkName: "components/dialog-value-line" */).then(c => wrapFunctional(c.default || c))
export const Dialog = () => import('../../components/Dialog/index.vue' /* webpackChunkName: "components/dialog" */).then(c => wrapFunctional(c.default || c))
export const FormBoolSwitch = () => import('../../components/Form/BoolSwitch.vue' /* webpackChunkName: "components/form-bool-switch" */).then(c => wrapFunctional(c.default || c))
export const FormCheckbox = () => import('../../components/Form/Checkbox.vue' /* webpackChunkName: "components/form-checkbox" */).then(c => wrapFunctional(c.default || c))
export const FormDatePicker = () => import('../../components/Form/DatePicker.vue' /* webpackChunkName: "components/form-date-picker" */).then(c => wrapFunctional(c.default || c))
export const FormFloat = () => import('../../components/Form/Float.vue' /* webpackChunkName: "components/form-float" */).then(c => wrapFunctional(c.default || c))
export const FormSelect = () => import('../../components/Form/Select.vue' /* webpackChunkName: "components/form-select" */).then(c => wrapFunctional(c.default || c))
export const FormSlider = () => import('../../components/Form/Slider.vue' /* webpackChunkName: "components/form-slider" */).then(c => wrapFunctional(c.default || c))
export const FormTimePicker = () => import('../../components/Form/TimePicker.vue' /* webpackChunkName: "components/form-time-picker" */).then(c => wrapFunctional(c.default || c))
export const HistoryGraph = () => import('../../components/History/Graph.vue' /* webpackChunkName: "components/history-graph" */).then(c => wrapFunctional(c.default || c))
export const HistoryRange = () => import('../../components/History/Range.vue' /* webpackChunkName: "components/history-range" */).then(c => wrapFunctional(c.default || c))
export const HistoryTable = () => import('../../components/History/Table.vue' /* webpackChunkName: "components/history-table" */).then(c => wrapFunctional(c.default || c))
export const History = () => import('../../components/History/index.vue' /* webpackChunkName: "components/history" */).then(c => wrapFunctional(c.default || c))
export const NodeTree = () => import('../../components/Node/Tree.vue' /* webpackChunkName: "components/node-tree" */).then(c => wrapFunctional(c.default || c))
export const OrganizationEditForm = () => import('../../components/Organization/EditForm.vue' /* webpackChunkName: "components/organization-edit-form" */).then(c => wrapFunctional(c.default || c))
export const OrganizationList = () => import('../../components/Organization/List.vue' /* webpackChunkName: "components/organization-list" */).then(c => wrapFunctional(c.default || c))
export const OrganizationMenu = () => import('../../components/Organization/Menu.vue' /* webpackChunkName: "components/organization-menu" */).then(c => wrapFunctional(c.default || c))
export const OrganizationOldEdit = () => import('../../components/Organization/oldEdit.vue' /* webpackChunkName: "components/organization-old-edit" */).then(c => wrapFunctional(c.default || c))
export const CardsWeather = () => import('../../components/cards/Weather.vue' /* webpackChunkName: "components/cards-weather" */).then(c => wrapFunctional(c.default || c))
export const CardHeaderDevice = () => import('../../components/Card/Header/Device.vue' /* webpackChunkName: "components/card-header-device" */).then(c => wrapFunctional(c.default || c))
export const CardHeaderNode = () => import('../../components/Card/Header/Node.vue' /* webpackChunkName: "components/card-header-node" */).then(c => wrapFunctional(c.default || c))
export const CardHeader = () => import('../../components/Card/Header/index.vue' /* webpackChunkName: "components/card-header" */).then(c => wrapFunctional(c.default || c))
export const CardSetupCheck = () => import('../../components/Card/Setup/Check.vue' /* webpackChunkName: "components/card-setup-check" */).then(c => wrapFunctional(c.default || c))
export const CardSetupFloat = () => import('../../components/Card/Setup/Float.vue' /* webpackChunkName: "components/card-setup-float" */).then(c => wrapFunctional(c.default || c))
export const CardSetupSelect = () => import('../../components/Card/Setup/Select.vue' /* webpackChunkName: "components/card-setup-select" */).then(c => wrapFunctional(c.default || c))
export const CardSetupSlider = () => import('../../components/Card/Setup/Slider.vue' /* webpackChunkName: "components/card-setup-slider" */).then(c => wrapFunctional(c.default || c))
export const CardSetupString = () => import('../../components/Card/Setup/String.vue' /* webpackChunkName: "components/card-setup-string" */).then(c => wrapFunctional(c.default || c))
export const CardSetup = () => import('../../components/Card/Setup/index.vue' /* webpackChunkName: "components/card-setup" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
