
/* eslint-disable no-console */

import { mapGetters, mapActions, mapMutations } from 'vuex';
import EditForm from './EditForm.vue';
// import { logger } from '~/utils/helpers';

export default {
  name: 'OrganizationList',

  components: { EditForm },

  /* Emits description
  emits: [
    {
      name: OrganizationList
      arguments:
        {
          cmd: ['createOrganization','selectedOrg'],
          selectedOrg: {
            id: Number,
            type: String,
            attributes: {
              type: String,
              name: String,
              description: String,
              avatar: String
            }
        }
      required: true
    }
  ]
  */

  data() {
    return {
      window: this.$CONST.WIN_ORGLIST,
      search: '',
      dialog: false,
      selected: [],
      paginationState: {},
      editForm: {
        action: this.$CONST.ACTION_CREATE,
        orgItem: {
          id: null,
          type: '',
          attributes: {
            type: '',
            name: '',
            description: '',
            avatar: null
          }
        }
      }
    };
  },

  computed: {
    ...mapGetters({
      user: 'getIdentity',
      orgsList: 'organization/getList',
      currentOrg: 'organization/getCurrent'
    }),

    // Is some org selected
    isSelected() {
      return this.selected.length > 0;
    },
    // Localize column's titles
    headers() {
      return [
        // {
        //   // text: this.$t('orgs.table.orgAvatar'), // Avatar
        //   value: 'attributes.avatar',
        //   sortable: false
        // },
        {
          text: this.$t('orgs.table.type'), // Organization type
          value: 'attributes.type',
          sortable: true
        },
        {
          text: this.$t('orgs.table.id'), // Id
          value: 'id',
          align: 'right',
          sortable: true
        },
        {
          text: this.$t('orgs.table.owner'), // Organization owner
          value: 'relationships.owner.attributes.nickname',
          sortable: true
        },
        {
          text: this.$t('orgs.table.name'), // Organization name
          value: 'attributes.name',
          sortable: true
        },
        {
          text: this.$t('orgs.table.description'), // Description
          value: 'attributes.description'
        },
        {
          text: this.$t('orgs.table.actions'), // Actions
          value: 'actions',
          width: '96px',
          sortable: false
        }
      ];
    },

    footerProps() {
      return {
        'items-per-page-text': this.$t(
          'orgs.table.footerProps.itemsPerPageText'
        ),
        'items-per-page-all-text': this.$t(
          'orgs.table.footerProps.itemsPerPageAllText'
        ),
        'page-text':
          this.paginationState.pageStart +
          1 +
          '–' +
          this.paginationState.pageStop +
          ' ' +
          this.$t('orgs.table.footerProps.pageText') +
          ' ' +
          this.paginationState.itemsLength
      };
    }
  },

  watch: {
    user(user) {
      if (user.ID) {
        this.loadOrgsList().then(() => {
          this.loadOrgSettings();
        });
      }
    },

    orgsList() {
      this.loadOrgSettings();

      if (this.orgsList.length === 0) {
        this.setCurrentOrg({});
        this.dialog = true;
      }
    },

    currentOrg(org) {
      if (org) {
        this.setSelected(org);
        this.saveOrgSettings(org);
      }
    },

    dialog(state) {
      this.window = state ? this.window : this.$CONST.WIN_ORGLIST;
    }
  },

  methods: {
    ...mapActions({
      loadOrgsList: 'organization/loadList',
      setCurrentByDefault: 'organization/setCurrentByDefault',
      createOrg: 'organization/create',
      updateOrg: 'organization/update',
      deleteOrg: 'organization/delete'
    }),
    ...mapMutations({
      setCurrentOrg: 'organization/setCurrent'
    }),

    setSelected(org) {
      if (org.id === null) return;
      this.$set(this.selected, 0, org);
    },
    selectById(id) {
      return this.orgsList.find((el) => el.id === id);
    },

    paginationUpdate(state) {
      this.paginationState = state;
    },
    orgChanged(org) {
      this.setSelected(org);
      this.setCurrentOrg(this.selected[0]);
      this.dialog = false;
      this.window = this.$CONST.WIN_ORGLIST;
    },
    createOrganization() {
      this.editForm.orgItem = {};
      this.editForm.action = this.$CONST.ACTION_CREATE;
      this.window = this.$CONST.WIN_ORGEDIT;
    },
    editOrganization(org) {
      this.editForm.orgItem = org;
      this.editForm.action = this.$CONST.ACTION_EDIT;
      this.window = this.$CONST.WIN_ORGEDIT;
    },
    editFormActions(eventParam) {
      this.window = this.$CONST.WIN_ORGLIST;
      switch (eventParam.cmd) {
        case this.$CONST.ACTION_CANCEL:
          break;
        case this.$CONST.ACTION_SAVE:
          this.createOrg(eventParam.orgItem);
          break;
        case this.$CONST.ACTION_UPDATE:
          this.updateOrg(eventParam.orgItem);
          break;
        case this.$CONST.ACTION_DELETE:
          this.deleteOrg(eventParam.orgItem.id);
          break;
        default:
          console.warn('Sorry, we are out of ' + eventParam.cmd + '.');
      }
    },

    // Збереження параметрів організації в localStorage
    saveOrgSettings(org) {
      if (!this.user.ID) return;
      const settingName = `${this.user.ID}.org`;
      if (org.id) {
        const stringified = JSON.stringify(org.id);
        localStorage.setItem(settingName, stringified);
        // logger('>>>>> >>>>>', 'SAVE ORG', stringified);
      } else {
        localStorage.removeItem(settingName);
      }
    },
    // Завантаження параметрів організації з localStorage
    loadOrgSettings() {
      // logger('<<<<< <<<<<', 'USER ID', this.user.ID);
      if (!this.user.ID) return;
      const settingName = `${this.user.ID}.org`;
      const loaded = localStorage.getItem(settingName);
      if (loaded) {
        try {
          const parsed = JSON.parse(loaded);
          const org = this.selectById(parsed);
          // logger('<<<<< <<<<<', 'LOAD ORG', org.id);
          this.setCurrentOrg(org);
        } catch (e) {
          localStorage.removeItem(settingName);
        }
      }
    }
  }
};
