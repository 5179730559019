
export default {
  name: 'EditForm',
  props: {
    action: {
      type: Number,
      required: true,
      default: 0 // this.$CONST.ORGTYPE_CREATE
    },
    orgItem: {
      type: Object,
      required: true
      // editOrg: {
      //   id: null,
      //   type: '',
      //   attributes: {
      //     type: '',
      //     name: '',
      //     description: '',
      //     avatar: null
      //   }
      // }
    }
  },
  data() {
    return {
      orgId: 0,
      orgType: this.$CONST.ORGTYPE_HOME,
      orgName: '',
      orgDescription: '',
      orgAvatar: null,
      formHasErrors: false,
      avatarAllowed: false // for future
    };
  },
  computed: {
    orgTypes() {
      return [
        {
          text: this.$t('editOrg.form.orgTypes.home'),
          value: this.$CONST.ORGTYPE_HOME,
          disabled: false
        },
        {
          text: this.$t('editOrg.form.orgTypes.business'),
          value: this.$CONST.ORGTYPE_BUSINESS,
          disabled: false
        }
      ];
    },
    isFormChanged() {
      return !(this.action === this.$CONST.ACTION_EDIT
        ? this.orgId === this.orgItem.id &&
          this.orgType === this.orgItem.attributes.type &&
          this.orgName === this.orgItem.attributes.name &&
          this.orgDescription === this.orgItem.attributes.description
        : // && this.orgAvatar == this.orgItem.attributes.avatar;
          this.orgId === 0 &&
          this.orgType === this.orgTypes[0].value &&
          this.orgName === '' &&
          this.orgDescription === '');
      // && this.orgAvatar == null;
    },
    msgRequired() {
      return this.$t('editOrg.rulesMsg.required');
    },
    msgAvatarSize() {
      return this.$t('editOrg.rulesMsg.avatarSize');
    },
    msgNameLen() {
      return this.$t('editOrg.rulesMsg.nameLen');
    },
    msgDescLen() {
      return this.$t('editOrg.rulesMsg.descLen');
    }
  },
  watch: {
    orgItem() {
      this.setCurrentOrg();
    },
    orgType() {
      this.formCheck();
    },
    orgName() {
      this.formCheck();
    },
    orgDescription() {
      this.formCheck();
    }
  },
  created() {
    this.setCurrentOrg();
  },
  methods: {
    setCurrentOrg() {
      if (this.action === this.$CONST.ACTION_EDIT) {
        this.orgId = this.orgItem.id;
        this.orgType = this.orgItem.attributes.type;
        this.orgName = this.orgItem.attributes.name;
        this.orgDescription = this.orgItem.attributes.description;
        this.orgAvatar = this.orgItem.attributes.avatar;
      } else {
        this.setBlankOrg();
      }
    },
    setBlankOrg() {
      this.orgId = 0;
      this.orgType = this.orgTypes[0].value;
      this.orgName = '';
      this.orgDescription = '';
      this.orgAvatar = null;
    },
    formCheck() {
      this.formHasErrors = !(
        this.$refs.orgDescription.validate(true) &&
        this.$refs.orgName.validate(true)
      );
    },
    resetForm() {
      this.setCurrentOrg();
    },
    saveOrg() {
      switch (this.action) {
        case this.$CONST.ACTION_CREATE:
          this.$emit('editFormActions', {
            cmd: this.$CONST.ACTION_SAVE,
            orgItem: {
              type: this.orgType,
              name: this.orgName,
              description: this.orgDescription,
              avatar: this.orgAvatar
            }
          });
          break;
        case this.$CONST.ACTION_EDIT:
          this.$emit('editFormActions', {
            cmd: this.$CONST.ACTION_UPDATE,
            orgItem: {
              id: this.orgId,
              name: this.orgName,
              description: this.orgDescription
            }
          });
          break;
        default:
          // eslint-disable-next-line no-console
          console.warn(`Sorry, we are out of ${this.action}.`);
      }
    },
    removeOrg() {
      this.$emit('editFormActions', {
        cmd: this.$CONST.ACTION_DELETE,
        orgItem: {
          id: this.orgId
        }
      });
    },
    cancel() {
      this.$emit('editFormActions', {
        cmd: this.$CONST.ACTION_CANCEL,
        orgItem: {}
      });
    }
  }
};
