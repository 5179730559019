import { render, staticRenderFns } from "./default.vue?vue&type=template&id=0b52390b&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrganizationList: require('/builds/iot/controller/lisa-back-html/components/Organization/List.vue').default,Language: require('/builds/iot/controller/lisa-back-html/components/Language.vue').default,ProfileMenu: require('/builds/iot/controller/lisa-back-html/components/ProfileMenu.vue').default,NavMenu: require('/builds/iot/controller/lisa-back-html/components/NavMenu.vue').default,NodeTree: require('/builds/iot/controller/lisa-back-html/components/Node/Tree.vue').default,Notification: require('/builds/iot/controller/lisa-back-html/components/Notification.vue').default})
