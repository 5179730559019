import enVuetify from 'vuetify/es5/locale/en';
export default {
  ...enVuetify,
  appName: 'lanet.house',

  auth: {
    title: 'Login',
    errorMsg: {
      failLoadIdentityInfo: 'Failed to get user info from server!',
      failLogout: 'Failed to logout!',
      axios: 'Axios error!'
    },
    label: {
      login: 'Login',
      logout: 'Password'
    },
    btn: {
      login: 'Login',
      logout: 'Logout'
    },
    message: {
      nameRequired: 'Name is required',
      nameLess: 'Name must be less than 16 characters',
      passRequired: 'Password is required',
      namePassWrong: "The login and password you entered don't match",
      accessGranted: 'Access granted'
    }
  },

  profile: {
    errorMsg: {
      failGetLog: "Failed to get log's data from server!",
      axios: 'Axios error!'
    },
    menu: {
      edit: 'Edit account',
      logout: 'Logout',
      chooseLang: 'Choose language',
      activityLog: 'Activity log'
    },
    activityLogTable: {
      title: 'My activity',
      footerProps: {
        itemsPerPageText: 'Items per page',
        itemsPerPageAllText: 'All',
        pageText: 'of'
      },
      id: 'Id',
      timeStamp: 'Date time',
      operation: 'Operation',
      description: 'Description',
      actions: 'Actions',
      label: {
        filterDates: 'Filter by dates',
        filterOp: 'Filter by operations',
        search: 'Search in the description'
      },
      datePicker: {
        btn: {
          apply: 'Apply',
          cancel: 'Cancel'
        }
      }
    }
  },

  navMenu: {
    dashboard: 'Dashboard',
    controllers: 'Controllers',
    devices: 'Devices',
    groups: 'Groups',
    groupsMenu: {
      home: 'Home',
      corp: 'Corp'
    },
    home: 'Home',
    corp: 'Corp',
    users: 'Users'
  },

  dashboard: {
    title: 'Dashboard',
    subtitle: '',
    label: {},
    welcome: {
      title: 'Welcome to lanet.house!',
      subtitle:
        'Soon there will be a dashboard for convenient use of selected devices'
    },
    btn: {
      addGroup: 'Add dashboard'
    },
    message: {
      noGroupsFound: 'No dashboards found'
    }
  },

  controllers: {
    title: 'Controllers',
    subtitle: 'сontrollers and coordinators',
    label: {},
    btn: {
      addDevice: 'Add device'
    },
    message: {
      noDevicesFound: 'No devices found'
    }
  },

  devices: {
    title: 'Devices',
    subtitle: 'sensors and actuators',

    label: {},
    btn: {
      addDevice: 'Add device'
    },

    errorMsg: {
      failAddDev: 'Failed to add a new device!',
      failDelDev: 'Failed to delete a device!',
      failUpdDev: 'Failed to update a device!',
      failStaDev: 'Failed to get a status of device!',
      failAddVirt: 'Failed to add a new virtual device!',
      failAddLine: 'Failed to add a line to a virtual device!',
      failDelLine: 'Failed to delele a line from a virtual device!',
      failGetValue: 'Failed to get the current value of a line!',
      failSetValue: 'Failed to set the current value of a line!',
      failLoadTypes: 'Failed to load a list of line types!',
      failGetSetting: 'Failed to load a device settings!',
      failSetSetting: 'Failed to save a device settings!'
    },

    message: {
      noDevicesFound: 'No devices found'
    }
  },

  orgs: {
    title: 'Select a group',
    subtitle: 'corporate and home',

    errorMsg: {
      failGetOrgs: "Failed to get group's data from server!",
      failAddOrg: 'Failed to add a new group!',
      failDelOrg: 'Failed to delete a group!',
      failUpdOrg: 'Failed to update a group!'
    },

    message: {
      noGroupsFound: 'No groups found',
      noOwner: 'Information not available'
    },

    menu: {
      edit: 'Settings',
      select: 'Select group'
    },

    btn: {
      newOrg: 'New group',
      cancel: 'Cancel',
      choose: 'Choose'
    },

    table: {
      title: 'My groups',
      lblSearch: 'Search',
      footerProps: {
        itemsPerPageText: 'Groups per page',
        itemsPerPageAllText: 'All',
        pageText: 'of'
      },
      id: 'Id',
      orgAvatar: 'Org avatar',
      owner: 'Owner',
      name: 'Name',
      type: 'Type',
      description: 'Description',
      actions: 'Actions'
    }
  },

  editOrg: {
    title: 'Settings',
    subtitle: 'of corporate or home',

    tooltip: {
      refreshForm: 'Refresh form'
    },

    rulesMsg: {
      avatarSize: 'Avatar size should be less than 2 MB!',
      required: 'This field is required',
      nameLen: 'Name must be between 3 and 25 characters!',
      descLen: 'Description must be less than 255 characters'
    },

    btn: {
      save: 'Save',
      delete: 'Delete',
      cancel: 'Cancel'
    },

    form: {
      lblOrgId: 'Group ID',
      phOrgId: 'Id',
      lblOrgAvatar: 'Avatar',
      phOrgAvatar: 'Pick an avatar',
      lblOrgType: 'Group type',
      phOrgType: 'Select...',
      lblOrgName: 'Group Name',
      phOrgName: `LTD 'Lanet Network'`,
      lblOrgDescription: 'Group description',
      phOrgDescription: 'ISP Provider',

      orgTypes: {
        home: 'Home',
        business: 'Business'
      }
    }
  },

  location: {
    title: 'Select an location',
    subtitle: '',

    errorMsg: {
      failGetLoc: "Failed to get location's data from server!",
      failAddLoc: 'Failed to add a new location!',
      failDelLoc: 'Failed to delete a location!',
      failUpdLoc: 'Failed to update a location!'
    },

    message: {
      noLocationsFound: 'No locations found'
    },

    lblSearch: 'Search',

    menu: {
      edit: 'Settings',
      select: 'Select...'
    },

    btn: {
      newLoc: 'New location',
      cancel: 'Cancel',
      choose: 'Choose'
    }
  },

  acl: {
    errorMsg: {
      failAddACL: 'Failed to add a new access rule!',
      failDelACL: 'Failed to delete an access rule!',
      failLstACL: 'Failed to get a list of access rules!'
    },
    del: {
      header: 'Delete access rule',
      bodyHead: 'Access rule',
      bodyChest: 'to node',
      bodyWaist: 'for',
      bodyTail: 'will be deleted.',
      accept: 'Delete'
    }
  },

  node: {
    title: 'Location',
    btn: {
      addNode: 'Location',
      addDev: 'Device',
      addRule: 'Rule'
    },
    menu: {
      addNode: 'Add location',
      editNode: 'Edit location',
      shareNode: 'Share location',
      delNode: 'Remove location',
      addDev: 'Add device',
      addRule: 'Add rule'
    },
    tip: {
      isRoot: 'You can not remove root node',
      notEmpty: 'You should remove children nodes first'
    },
    tab: {
      info: 'Info',
      child: 'Children',
      access: 'Access'
    },
    confirm: {
      sure: 'Are you sure?'
    },
    add: {
      header: 'Add a new location',
      bodyHead: 'A new child location will be added to the location',
      bodyTail: '.',
      label: 'Location name',
      accept: 'Add'
    },
    edit: {
      header: 'Update location',
      bodyHead: 'Enter a new name for the location',
      bodyTail: '.',
      label: 'Location name',
      accept: 'Update'
    },
    share: {
      header: 'Share location',
      bodyHead:
        'Enter the name or email of the user with whom you want to share the location',
      bodyTail: '. Also specify the access level for this user.',
      label: 'User name or email',
      noData: 'Search for a user with whom you want to share the location',
      accept: 'Share'
    },
    del: {
      header: 'Delete location',
      bodyHead: 'Location',
      bodyTail: 'will be deleted.',
      accept: 'Delete'
    },
    is: {
      shared: 'This node is shared'
    }
  },

  device: {
    title: 'Device',
    btn: {
      refresh: 'Refresh',
      edit: 'Edit',
      remove: 'Remove',
      table: 'Table',
      chart: 'Chart',
      repeatOn: 'Repeat',
      repeatOff: 'Break'
    },
    menu: {
      edit: 'Edit device',
      share: 'Share device',
      remove: 'Remove device'
    },
    tab: {
      info: 'Info',
      lines: 'Lines',
      config: 'Config',
      status: 'Status',
      errors: 'Errors',
      access: 'Access',
      setup: 'Settings'
    },
    confirm: {
      sure: 'Are you sure?'
    },
    add: {
      header: 'Add a new device',
      bodyHead: 'A new device will be added to the location',
      bodyTail: '.',
      labelId: 'Device HWID',
      labelName: 'Device name',
      accept: 'Add'
    },
    edit: {
      header: 'Update device',
      bodyHead: 'Enter a new name for the device',
      bodyTail: '.',
      labelId: 'Device HWID',
      labelName: 'Device name',
      accept: 'Update'
    },
    share: {
      header: 'Share device',
      bodyHead:
        'Enter the name or email of the user with whom you want to share the device',
      bodyTail: '. Also specify the access level for this user.',
      label: 'User name or email',
      noData: 'Search for a user with whom you want to share the device',
      accept: 'Share'
    },
    del: {
      header: 'Delete device',
      bodyHead: 'Device',
      bodyTail: 'will be deleted.',
      accept: 'Delete'
    },
    qr: {
      header: 'Scan QR-code',
      init: 'Please grant access to the camera. ',
      scan: 'Aim the camera at the QR-code and wait for the recognition to complete.',
      error: 'An error has occurred.',
      errors: {
        NotAllowedError: 'User denied camera access permisson.',
        NotFoundError: 'No suitable camera device installed.',
        NotSupportedError: 'The page is not served over HTTPS (or localhost)',
        NotReadableError: 'Maybe camera is already in use',
        OverconstrainedError:
          'Did you requested the front camera although there is none?',
        StreamApiNotSupportedError: 'Browser seems to be lacking features'
      }
    },
    reset: {
      button: 'Reset',
      header: 'Reset device settings',
      bodyHead: 'The settings for device',
      bodyTail: 'will be reseted to default.',
      accept: 'Reset'
    },
    is: {
      shared: 'This device is shared'
    },
    state: {
      VIRTUAL: 'Virtual Device',
      ONLINE: 'Online Real Device',
      OFFLINE: 'Offline Device',
      UNKNOWN: 'Unregistered Device'
    }
  },

  line: {
    add: {
      header: 'Add a new line',
      bodyHead: 'A new line will be added to the device',
      bodyTail: '. Enter a name, description, and type.',
      labelName: 'Line name',
      labelDesc: 'Description',
      labelType: 'Line type',
      accept: 'Add'
    },
    edit: {
      header: 'Update line',
      bodyHead: 'Enter a new name for the line',
      bodyTail: '.',
      labelName: 'Line name',
      labelDesc: 'Description',
      accept: 'Update'
    },
    value: {
      header: 'Change value',
      bodyHead: 'Enter a new value for the line',
      bodyTail: '.',
      label: 'Value',
      accept: 'Change'
    },
    del: {
      header: 'Delete line',
      bodyHead: 'Line',
      bodyTail: ' will be deleted.',
      accept: 'Delete'
    }
  },

  iface: {
    btn: {
      reject: 'Cancel'
    },
    tip: {
      owner: 'You should be owner to share'
    },
    search: {
      user: 'Search for a user...',
      hint: 'Type at least 3 symbols'
    },
    label: {
      date: 'Date',
      time: 'Time'
    },
    level: {
      owner: 'Owner',
      admin: 'Admin',
      user: 'User',
      none: 'None'
    },
    origin: {
      real: 'Real',
      virt: 'Virtual'
    },
    range: {
      min_15: '15 min',
      min_30: '30 min',
      hour_1: '1 hr',
      hour_4: '4 hrs'
    },
    type: {
      toggle_switch: 'Toggle switch',
      rgb_color: 'RGB color'
    },
    prop: {
      org_id: 'Group ID',
      id: 'ID',
      hwid: 'HWID',
      name: 'Name',
      node: 'Node',
      type: 'Type',
      parent: 'Parent',
      access_level: 'Access level',

      topic: 'Topic',
      value: 'Value',
      unit: 'Unit',
      date: 'Date',
      level: 'Level',
      user: 'User',
      error: 'Error',
      actions: 'Act',

      device_name: 'Device name',
      model_name: 'Model name',
      hardware: 'Hardware ver.',
      software: 'Software ver.',
      release: 'Release',
      build: 'Build',
      manufacturer: 'Manufacturer',
      power: 'Power',
      can_listen: 'Can listen',

      temperature: 'Temperature',
      connection: 'Connection',
      rssi: 'RSSI',
      quality: 'Quality',
      state: 'State',

      keep_alive: 'Keep alive',
      time_publish: 'Time publish',
      battery_type: 'Battery type',
      charging_current: 'Charging current'
    },
    err: {
      require: 'This field is required',
      space: 'No spaces are allowed',
      empty: 'This field must be not empty',
      alphanum: 'Only Latin letters and numbers are allowed',
      decimal: 'Only decimal characters are allowed',
      hexadec: 'Only hexadecimal characters are allowed',
      minHead: 'A minimum of',
      minTail: 'characters is allowed',
      maxHead: 'A maximum of',
      maxTail: 'characters is allowed',
      minimum: 'Minimum value:',
      maximum: 'Maximum value:',
      nothing: 'There is nothing here',
      notOwner: 'You cannot choose the owner',
      earlier: 'earlier than start'
    }
  }
};
