
export default {
  name: 'Language',

  data() {
    return {};
  },

  computed: {
    locales() {
      return this.$i18n.locales.filter((l) => l.code !== this.$i18n.locale);
    },

    current() {
      return this.$i18n.locales.find((l) => l.code === this.$i18n.locale);
    }
  },

  methods: {
    setLocale(locale) {
      this.$i18n.setLocale(locale);
      this.$moment.locale(locale);
    }
  }
};
