import { render, staticRenderFns } from "./ProfileMenu.vue?vue&type=template&id=d5dedc86&"
import script from "./ProfileMenu.vue?vue&type=script&lang=js&"
export * from "./ProfileMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Language: require('/builds/iot/controller/lisa-back-html/components/Language.vue').default})
