import { getErrorResponse } from '~/utils/apiUtils';

// https://kp-staging.lanet.house/public/#api-Location
export default ($axios) => ({
  // https://kp-staging.lanet.house/public/#api-Location-PostOrgLocation
  // Create a new location
  async create(orgId, node, name) {
    try {
      const { status, data, errors } = await $axios.$post(
        `/v1/org/${orgId}/node`,
        {
          node,
          name
        }
      );
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-Location-PatchOrgLocationNode
  // Update a location
  async update(orgId, node, name) {
    try {
      const { status, data, errors } = await $axios.$patch(
        `/v1/org/${orgId}/node`,
        {
          node,
          name
        }
      );
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-Location-DelOrgLocation
  // Delete a location
  async delete(orgId, node) {
    try {
      const { status, data, errors } = await $axios.$delete(
        `/v1/org/${orgId}/node`,
        {
          params: { node }
        }
      );
      if (status === 'ok') {
        return {
          status,
          data,
          errors
        };
      }
      throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-Location-GetLocationTree
  // Get organization's locations list
  async list(orgId = '', depth = 0, include = '', filter = '') {
    try {
      const { status, data, errors } = await $axios.$get(
        `/v1/org/${orgId}/tree`,
        {
          params: { depth, include, filter }
        }
      );
      return {
        status,
        data,
        errors
      };
      // throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  },

  // https://kp-staging.lanet.house/public/#api-Location-GetOrgLocationNode
  // Get location's info
  async info(orgId, node) {
    try {
      const { status, data, errors } = await $axios.$get(
        `/v1/org/${orgId}/node`,
        {
          params: { node }
        }
      );
      return {
        status,
        data,
        errors
      };
      // throw new SyntaxError('Undefined response data format');
    } catch (e) {
      return getErrorResponse(e);
    }
  }
});
