import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _105ba883 = () => interopDefault(import('../pages/activityLog.vue' /* webpackChunkName: "pages/activityLog" */))
const _54d14e4a = () => interopDefault(import('../pages/auth.vue' /* webpackChunkName: "pages/auth" */))
const _744323b6 = () => interopDefault(import('../pages/controllers.vue' /* webpackChunkName: "pages/controllers" */))
const _5c562d78 = () => interopDefault(import('../pages/device.vue' /* webpackChunkName: "pages/device" */))
const _321b22ab = () => interopDefault(import('../pages/devices.vue' /* webpackChunkName: "pages/devices" */))
const _426361a4 = () => interopDefault(import('../pages/node.vue' /* webpackChunkName: "pages/node" */))
const _67802e6a = () => interopDefault(import('../pages/VideoPlayer.vue' /* webpackChunkName: "pages/VideoPlayer" */))
const _eee19e92 = () => interopDefault(import('../pages/VideoPlayer1.vue' /* webpackChunkName: "pages/VideoPlayer1" */))
const _555d916f = () => interopDefault(import('../pages/Node/_id.vue' /* webpackChunkName: "pages/Node/_id" */))
const _fb7f9a8a = () => interopDefault(import('../pages/Organization/_action.vue' /* webpackChunkName: "pages/Organization/_action" */))
const _7e78dcc0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/activityLog",
    component: _105ba883,
    pathToRegexpOptions: {"strict":true},
    name: "activityLog"
  }, {
    path: "/auth",
    component: _54d14e4a,
    pathToRegexpOptions: {"strict":true},
    name: "auth"
  }, {
    path: "/controllers",
    component: _744323b6,
    pathToRegexpOptions: {"strict":true},
    name: "controllers"
  }, {
    path: "/device",
    component: _5c562d78,
    pathToRegexpOptions: {"strict":true},
    name: "device"
  }, {
    path: "/devices",
    component: _321b22ab,
    pathToRegexpOptions: {"strict":true},
    name: "devices"
  }, {
    path: "/node",
    component: _426361a4,
    pathToRegexpOptions: {"strict":true},
    name: "node"
  }, {
    path: "/VideoPlayer",
    component: _67802e6a,
    pathToRegexpOptions: {"strict":true},
    name: "VideoPlayer"
  }, {
    path: "/VideoPlayer1",
    component: _eee19e92,
    pathToRegexpOptions: {"strict":true},
    name: "VideoPlayer1"
  }, {
    path: "/Node/:id?",
    component: _555d916f,
    pathToRegexpOptions: {"strict":true},
    name: "Node-id"
  }, {
    path: "/Organization/:action?",
    component: _fb7f9a8a,
    pathToRegexpOptions: {"strict":true},
    name: "Organization-action"
  }, {
    path: "/",
    component: _7e78dcc0,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
