import { prepareError, logger } from '~/utils/helpers';
export const state = () => ({
  // identity — an identified and authorized person
  identity: {
    ID: null,
    is_root: false,
    CreatedAt: '',
    DeletedAt: null,
    UpdatedAt: '',
    avatar_url: '',
    email: '',
    first_name: '',
    last_name: '',
    nick_name: ''
  },
  token: null,
  locales: ['en', 'uk'],
  locale: 'uk',
  error: {
    module: null,
    errorText: null
  }
});

export const mutations = {
  setIdentity(state, identity) {
    if (Object.keys(identity).length === 0 && identity.constructor === Object) {
      state.identity = {
        ID: null,
        is_root: false,
        CreatedAt: '',
        DeletedAt: null,
        UpdatedAt: '',
        avatar_url: '',
        email: '',
        first_name: '',
        last_name: '',
        nick_name: ''
      };
    } else {
      state.identity = identity;
    }
  },
  setToken(state, token) {
    state.token = token;
  },
  clearToken(state) {
    state.token = null;
  },
  setError(state, error) {
    state.error = error;
  },
  clearError(state) {
    state.error.module = null;
    state.error.errorText = null;
  }
};

export const getters = {
  getIdentity: (s) => s.identity,
  hasToken: (s) => !!s.token,
  error: (s) => s.error
};

export const actions = {
  login({ commit, $config }, identity) {
    // Redirect to auth page by google auth
    this.$lisaAPI.auth.login();
  },

  // Load Identity Info
  loadIdentityInfo({ commit, state, $config }) {
    this.loading = true;
    this.$lisaAPI.user
      .me()
      .then((res) => {
        if (res.status === 'ok') {
          commit('setIdentity', res.data);
          // logger('<[ STORE ]>', 'LOAD IDENTITY', this.loading);
          logger('<[ STORE ]>', 'SET IDENTITY', res.data);
        } else {
          commit('setIdentity', {});
          commit(
            'setError',
            prepareError('Store', 'auth.errorMsg.failLoadIdentityInfo')
          );
        }
      })
      .finally(() => (this.loading = false));
  },

  // Logout and redirect
  logout({ commit }) {
    return this.$lisaAPI.auth
      .logout()
      .then((res) => {
        if (res.status === 'ok') {
          commit('clearToken');
          commit('setIdentity', {});
        } else {
          // For components: this.$options.name
          commit('setError', prepareError('Store', 'auth.errorMsg.failLogout'));
        }
        this.$router.push(this.app.localePath('auth'));
      })
      .finally(() => (this.loading = false));
  }
};
