import ukVuetify from 'vuetify/es5/locale/uk';
export default {
  ...ukVuetify,
  appName: 'lanet.house',

  auth: {
    title: 'Вхід',
    errorMsg: {
      failLoadIdentityInfo:
        'Виникла помилка під час отримання даних користувача!',
      failLogout: 'Помилка виходу з системи!',
      axios: 'Axios error!'
    },
    label: {
      login: 'Логін',
      logout: 'Пароль'
    },
    btn: {
      login: 'Увійти',
      logout: 'Вийти'
    },
    message: {
      nameRequired: "Обов'язкове поле",
      nameLess: 'Логін повинен містити менше 16 символів',
      passRequired: "Обов'язкове поле",
      namePassWrong: 'Введені логін та пароль не збігаються',
      accessGranted: 'Доступ схвалено'
    },
    errorMessage: {
      groupsHomeAPI: 'Не вдалось отримати дані від сервера!',
      groupsHomeAPIAdd: 'Не вдалось додати запис!'
    }
  },

  profile: {
    errorMsg: {
      failGetLog: 'Виникла помилка під час отримання даних журналу подій!',
      axios: 'Axios error!'
    },
    menu: {
      edit: 'Редагувати профіль',
      logout: 'Вийти з системи',
      chooseLang: 'Вибрати мову',
      activityLog: 'Журнал подій'
    },
    activityLogTable: {
      title: 'Журнал подій',
      footerProps: {
        itemsPerPageText: 'Подій на сторінці',
        itemsPerPageAllText: 'Усі',
        pageText: 'з'
      },
      id: 'Код',
      timeStamp: 'Час події',
      operation: 'Операція',
      description: 'Опис',
      actions: 'Дії',
      label: {
        filterDates: 'Фільтр по датах',
        filterOp: 'Фільтр по операціях',
        search: 'Пошук в описі'
      },
      datePicker: {
        btn: {
          apply: 'Застосувати',
          cancel: 'Відмінити'
        }
      }
    }
  },

  navMenu: {
    dashboard: 'Основна панель',
    controllers: 'Контролери',
    devices: 'Пристрої',
    groups: 'Групи',
    groupsMenu: {
      home: 'Домашні',
      corp: 'Корпоративні'
    },
    home: 'Домашні',
    corp: 'Організації',
    users: 'Користувачі'
  },

  dashboard: {
    title: 'Панель приладів',
    subtitle: '',
    label: {},
    welcome: {
      title: 'Ласкаво просимо в lanet.house!',
      subtitle:
        'Невдовзі тут з`явиться дашборд для зручного користування вибраними пристроями'
    },
    btn: {
      addGroup: 'Додати панель'
    },
    message: {
      noGroupsFound: 'Не знайдено жодної панелі'
    }
  },

  controllers: {
    title: 'Контролери',
    subtitle: 'контролери та координатори',
    label: {},
    btn: {
      addDevice: 'Додати пристрій'
    },
    message: {
      noDevicesFound: 'Не знайдено жодного пристрою'
    }
  },

  devices: {
    title: 'Пристрої',
    subtitle: 'cенсори та актуатори',

    label: {},
    btn: {
      addDevice: 'Додати пристрій'
    },

    errorMsg: {
      failAddDev: 'Виникла помилка під час створення нового пристрою!',
      failDelDev: 'Виникла помилка під час видалення пристрою!',
      failUpdDev: 'Виникла помилка під час оновлення пристрою!',
      failStaDev: 'Виникла помилка під час отримання стану пристрою!',
      failAddVirt: 'Виникла помилка під час створення віртуального пристрою!',
      failAddLine: 'Виникла помилка під час додавання нової лінії до пристрою!',
      failDelLine: 'Виникла помилка під час видалення лінії з пристрою!',
      failGetValue: 'Виникла помилка під час отримання значення лінії!',
      failSetValue: 'Виникла помилка під час збереження значення лінії!',
      failLoadTypes: 'Виникла помилка під час отримання списку типів ліній!',
      failGetSetting: 'Виникла помилка під час отримання налаштувань пристрою!',
      failSetSetting: 'Виникла помилка під час збереження налаштувань пристрою!'
    },

    message: {
      noDevicesFound: 'Не знайдено жодного пристрою'
    }
  },

  orgs: {
    title: 'Оберіть організацію чи групу',
    subtitle: 'корпоративні та домашні',

    errorMsg: {
      failGetOrgs: 'Виникла помилка під час отримання даних про групи!',
      failAddOrg: 'Виникла помилка під час створеня нової групи!',
      failDelOrg: 'Виникла помилка під час видалення групи!',
      failUpdOrg: 'Виникла помилка під час оновлення групи!'
    },

    message: {
      noGroupsFound: 'Не знайдено жодної групи',
      noOwner: 'Інформація недоступна'
    },

    menu: {
      edit: 'Налаштування',
      select: 'Оберіть групу'
    },

    btn: {
      newOrg: 'Створити',
      cancel: 'Відмінити',
      choose: 'Обрати'
    },

    table: {
      title: 'Мої групи',
      lblSearch: 'Знайти',
      footerProps: {
        itemsPerPageText: 'Груп на сторінці',
        itemsPerPageAllText: 'Усі',
        pageText: 'з'
      },
      id: 'Код',
      orgAvatar: 'Аватар',
      owner: 'Власник',
      name: 'Назва',
      type: 'Тип',
      description: 'Опис',
      actions: 'Дії'
    }
  },

  editOrg: {
    title: 'Налаштування',
    subtitle: 'групи чи організації',

    tooltip: {
      refreshForm: 'Оновити форму'
    },
    rulesMsg: {
      avatarSize: 'Розмір файлу має бути менше 2 MB!',
      required: "Це обов'язкове поле!",
      nameLen: 'Назва має містити від 3 до 25 символів!',
      descLen: 'Опис має містити менше 255 символів!'
    },

    btn: {
      save: 'Зберегти',
      delete: 'Видалити',
      cancel: 'Відмінити'
    },

    form: {
      lblOrgId: 'Код групи',
      phOrgId: 'Код',
      lblOrgAvatar: 'Аватар',
      phOrgAvatar: 'Оберіть файл з аватаром',
      lblOrgType: 'Тип групи',
      phOrgType: 'Оберіть...',
      lblOrgName: 'Назва групи',
      phOrgName: "ТОВ 'Мережа Ланет'",
      lblOrgDescription: 'Опис групи',
      phOrgDescription: 'ISP провайдер',

      orgTypes: {
        home: 'Домашня',
        business: 'Організація'
      }
    }
  },

  location: {
    title: 'Оберіть локацію',
    subtitle: '',

    errorMsg: {
      failGetLoc: 'Виникла помилка під час отримання даних про локації!',
      failAddLoc: 'Виникла помилка під час створеня нової локації!',
      failDelLoc: 'Виникла помилка під час видалення локації!',
      failUpdLoc: 'Виникла помилка під час оновлення локації!'
    },

    message: {
      noLocationsFound: 'Не знайдено жодної локації'
    },

    lblSearch: 'Знайти',

    menu: {
      edit: 'Налаштування',
      select: 'Оберіть...'
    },

    btn: {
      newLoc: 'Створити',
      cancel: 'Відмінити',
      choose: 'Обрати'
    }
  },

  acl: {
    errorMsg: {
      failAddACL: 'Виникла помилка під час створеня нового правила доступу!',
      failDelACL: 'Виникла помилка під час видалення правила доступу!',
      failLstACL: 'Виникла помилка під час отримання списку правил доступу!'
    },
    del: {
      header: 'Видалення правила доступу',
      bodyHead: 'Правило доступу',
      bodyChest: 'до вузла',
      bodyWaist: 'для',
      bodyTail: 'буде видалено.',
      accept: 'Видалити'
    }
  },

  node: {
    title: 'Локація',
    btn: {
      addNode: 'Локація',
      addDev: 'Пристрій',
      addRule: 'Правило'
    },
    menu: {
      addNode: 'Додати локацію',
      editNode: 'Назвати локацію',
      shareNode: 'Поділитися локацією',
      delNode: 'Видалити локацію',
      addDev: 'Додати пристрій',
      addRule: 'Додати правило'
    },
    tip: {
      isRoot: 'Не можна видаляти кореневу локацію',
      notEmpty: 'Слід спершу видалити дочірні'
    },
    tab: {
      info: 'Інфо',
      child: 'Дочірні',
      access: 'Доступ'
    },
    confirm: {
      sure: 'Ви впевнені?'
    },
    add: {
      header: 'Додавання нової локації',
      bodyHead: 'До локації ',
      bodyTail: ' буде додано нову дочірню локацію.',
      label: 'Назва локації',
      accept: 'Додати'
    },
    edit: {
      header: 'Зміна назви локації',
      bodyHead: 'Введіть нову назву для локації',
      bodyTail: '.',
      label: 'Назва локації',
      accept: 'Змінити'
    },
    share: {
      header: 'Доступ до локації',
      bodyHead:
        'Введіть ім`я або email користувача, з яким ви хочете поділитися локацією',
      bodyTail: '. Також вкажіть рівень доступу для цього користувача.',
      label: 'Ім`я або email користувача',
      noData: 'Пошук користувача, з яким ви хочете поділитися локацією',
      accept: 'Поділитися'
    },
    del: {
      header: 'Видалення локації',
      bodyHead: 'Локацію',
      bodyTail: 'буде видалено.',
      accept: 'Видалити'
    },
    is: {
      shared: 'Цю локацію надано у спільний доступ'
    }
  },

  device: {
    title: 'Пристрій',
    btn: {
      refresh: 'Оновити',
      edit: 'Назвати',
      remove: 'Видалити',
      table: 'Таблиця',
      chart: 'Графік',
      repeatOn: 'Повтор',
      repeatOff: 'Припинити'
    },
    menu: {
      edit: 'Назвати пристрій',
      share: 'Поділитися пристроєм',
      remove: 'Видалити пристрій'
    },
    tab: {
      info: 'Інфо',
      lines: 'Лінії',
      config: 'Конфіг',
      status: 'Статус',
      errors: 'Помилки',
      access: 'Доступ',
      setup: 'Настройки'
    },
    confirm: {
      sure: 'Ви впевнені?'
    },
    add: {
      header: 'Додавання нового пристрою',
      bodyHead: 'До локації',
      bodyTail: ' буде додано новий пристрій.',
      labelId: 'HWID пристрою',
      labelName: 'Назва пристрою',
      accept: 'Додати'
    },
    edit: {
      header: 'Зміна назви пристрою',
      bodyHead: 'Введіть нову назву для пристрою',
      bodyTail: '.',
      labelId: 'HWID пристрою',
      labelName: 'Назва пристрою',
      accept: 'Змінити'
    },
    share: {
      header: 'Доступ до пристрою',
      bodyHead:
        'Введіть ім`я або email користувача, з яким ви хочете поділитися пристроєм',
      bodyTail: '. Також вкажіть рівень доступу для цього користувача.',
      label: 'Ім`я або email користувача',
      noData: 'Пошук користувача, з яким ви хочете поділитися пристроєм',
      accept: 'Поділитися'
    },
    del: {
      header: 'Видалення пристрою',
      bodyHead: 'Пристрій',
      bodyTail: 'буде видалено.',
      accept: 'Видалити'
    },
    qr: {
      header: 'Сканування QR-коду',
      init: 'Будь-ласка надайте доступ до камери. ',
      scan: 'Наведіть камеру на QR-код і очікуйте завершення розпізнавання.',
      error: 'Виникла помилка.',
      errors: {
        NotAllowedError: 'Користувач заборонив доступ до камери.',
        NotFoundError: 'Не встановлено відповідний пристрій камери.',
        NotSupportedError:
          'Сторінка не обслуговується через HTTPS (або localhost)',
        NotReadableError: 'Можливо, камера вже використовується',
        OverconstrainedError: 'Ви запросили фронтальну камеру, хоча її немає?',
        StreamApiNotSupportedError: 'Здається, браузеру не вистачає функцій'
      }
    },
    reset: {
      button: 'Скинути',
      header: 'Скидання налаштувань пристрою',
      bodyHead: 'Налаштування пристрою',
      bodyTail: 'буде скинуто до заводських налаштувань.',
      accept: 'Скинути'
    },
    is: {
      shared: 'Цей пристрій надано у спільний доступ'
    },
    state: {
      VIRTUAL: 'Віртуальний пристрій',
      ONLINE: 'Реальний пристрій онлайн',
      OFFLINE: 'Пристрій оффлайн',
      UNKNOWN: 'Незареєстрований пристрій'
    }
  },

  line: {
    add: {
      header: 'Додавання нової лінії',
      bodyHead: 'До пристрою',
      bodyTail: ' буде додано нову лінію. Введіть назву, опис та тип.',
      labelName: 'Назва лінії',
      labelDesc: 'Опис лінії',
      labelType: 'Тип лінії',
      accept: 'Додати'
    },
    edit: {
      header: 'Зміна назви лінії',
      bodyHead: 'Введіть нову назву для лінії',
      bodyTail: '.',
      labelName: 'Назва лінії',
      labelDesc: 'Опис лінії',
      accept: 'Змінити'
    },
    value: {
      header: 'Зміна стану лінії',
      bodyHead: 'Введіть новий стан лінії',
      bodyTail: '.',
      label: 'Стан',
      accept: 'Змінити'
    },
    del: {
      header: 'Видалення лінії',
      bodyHead: 'Лінію',
      bodyTail: ' буде видалено.',
      accept: 'Видалити'
    }
  },

  iface: {
    btn: {
      reject: 'Скасувати'
    },
    tip: {
      owner: 'Ви повинні бути власником, щоб поділитися'
    },
    search: {
      user: 'Пошук користувача...',
      hint: 'Введіть принаймні 3 символи'
    },
    label: {
      date: 'Дата',
      time: 'Час'
    },
    level: {
      owner: 'Власник',
      admin: 'Адміністратор',
      user: 'Користувач',
      none: 'Без доступу'
    },
    origin: {
      real: 'Реальний',
      virt: 'Віртуальний'
    },
    range: {
      min_15: '15 хв',
      min_30: '30 хв',
      hour_1: '1 год',
      hour_4: '4 год'
    },
    type: {
      toggle_switch: 'Перемикач',
      rgb_color: 'Колір RGB'
    },
    prop: {
      org_id: 'ID групи',
      id: 'ID',
      hwid: 'HWID',
      name: 'Назва',
      node: 'Локація',
      type: 'Тип',
      parent: 'Предок',
      access_level: 'Рівень доступу',

      topic: 'Шлях',
      value: 'Значення',
      unit: 'Од.',
      date: 'Дата',
      level: 'Рівень',
      user: 'Користувач',
      error: 'Помилка',
      actions: 'Дії',

      device_name: 'Назва пристрою',
      model_name: 'Назва моделі',
      hardware: 'Версія обладнання',
      software: 'Версія програми',
      release: 'Реліз',
      build: 'Білд',
      manufacturer: 'Виробник',
      power: 'Живлення',
      can_listen: 'Може слухати',

      temperature: 'Температура',
      connection: 'З`єднання',
      rssi: 'RSSI',
      quality: 'Якість',
      state: 'Стан',

      keep_alive: 'Тримати активним',
      time_publish: 'Час між сеансами',
      battery_type: 'Тип батареї',
      charging_current: 'Зарядний струм'
    },
    err: {
      require: 'Це поле є обов`язковим',
      space: 'Пропуски не допускаються',
      empty: 'Це поле повинно бути не порожнім',
      alphanum: 'Допускаються лише латинські літери та цифри',
      decimal: 'Допускаються лише десяткові символи',
      hexadec: 'Допускаються лише шістнадцяткові символи',
      minHead: 'Допускається мінімум',
      minTail: 'символів',
      maxHead: 'Допускається максимум',
      maxTail: 'символів',
      minimum: 'Мінімальне значення:',
      maximum: 'Максимальне значення:',
      nothing: 'Тут порожньо',
      notOwner: 'Не можна обирати власника',
      earlier: 'Раніше початку'
    }
  }
};
