/* eslint-disable no-console */

export function prepareError(module, errorText) {
  return {
    module,
    errorText
  };
}

// Find object in nested array using recursion
export const findItemNested = (arr, itemId, nestingKey) =>
  arr.reduce((a, item) => {
    // if (item.id === itemId) logger('<Find Item>', 'FOUND', item);

    if (a) return a;
    if (item.id === itemId) return item;
    if (item[nestingKey])
      return findItemNested(item[nestingKey], itemId, nestingKey);
    logger('<Find Item>', 'FOUND', 'NOTHING');
    return undefined;
  }, null);

// Find org ID by node ID
export const getOrgId = (id) => {
  // logger('<Get OrgID>', 'ID', id);
  if (id) return id.split('/', 1).toString();
};

// Find parent of node by ID
export const getParent = (id) => {
  if (typeof id !== 'string') return id;
  const slash = id.lastIndexOf('/');
  const parent = slash === -1 ? '' : id.slice(0, slash);
  return parent;
};

// Find HWID by node ID
export const getHWID = (id) => {
  // logger('<Get  HWID>', 'ID', id);
  if (typeof id !== 'string') return id;
  const slash = id.lastIndexOf('/');
  const hwid = slash === -1 ? '' : id.slice(slash + 1);
  return hwid;
};

// Is the device virtual?
export const isVirt = (hwid) => {
  if (typeof hwid !== 'string') return false;
  return hwid.length === 16 && hwid[0] === 'V';
};

// Is the node root?
export const isRoot = (id) => {
  return id === getOrgId(id);
};

// Return icon for node
export const getIcon = (el) => {
  const types = {
    org: 'mdi-factory', // 'mdi-domain',
    loc: 'mdi-map-marker',
    cnt: 'mdi-router-network',
    sns: 'mdi-motion-sensor',
    usr: 'mdi-account',
    rul: 'mdi-script-outline',
    map: 'mdi-map',
    node: 'mdi-map-marker',
    device: 'mdi-switch', // 'mdi-router-network',
    info: 'mdi-information-outline',
    help: 'mdi-help-circle', // mdi-help-circle-outline
    alert: 'mdi-alert-circle-outline' // mdi-alert-circle
  };

  if (isRoot(el.id)) return types.org;

  if (el.type)
    if (types[el.type]) return types[el.type];
    else return types.alert;
};

// Transform the first letter of string to uppercase
export const capitalizeFirstLetter = (
  [first, ...rest],
  locale = navigator.language
) => {
  return first === undefined
    ? ''
    : first.toLocaleUpperCase(locale) + rest.join('');
};

// Replace underscore character to space and capitalize string
export const prepareString = (value) => {
  if (typeof value !== 'string') return value;
  return capitalizeFirstLetter(value.replace(/_/g, ' '));
};

// Parse string to object
export const parseString = (str) => {
  let obj = {};
  str.split(',').forEach((el) => {
    obj = { ...obj, ...Object.fromEntries([el.trim().split('=')]) };
  });
  return obj;
};

// Inserts a space after a comma and colon, removes quotation marks and parentheses
export const formatString = (value) => {
  if (typeof value !== 'string') return value;
  return value
    .replace(/([,:])/g, '$1 ')
    .replace(/\\"/g, '`')
    .replace(/[{"}]/g, '')
    .replace(/`/g, '"');
};

// Transform JSON to string and call formatString
export const formatJSON = (value) => {
  if (typeof value !== 'object') return value;
  return formatString(JSON.stringify(value));
};

// Crop string and fill spaces to fix length
export const alignStr = (str, len, dir = 'R') => {
  let string = str.slice(0, len);
  if (dir === 'R') string = string.padStart(len);
  else string = string.padEnd(len);
  return string;
};

// Formatted console log
export const logger = (prefix, prop, value) => {
  const lenPrefix = 11;
  const lenProp = 14;
  const length = 68;
  const string =
    alignStr(prefix, lenPrefix, 'L') +
    '   ' +
    alignStr(prop, lenProp, 'R') +
    '   ' +
    formatJSON(value);
  console.log(string.slice(0, length) + (string.length > length ? '...' : ''));
};

// Add type to object
export const addType = (type) => (item) => {
  return { ...item, type };
};

// Add prop to object
export const addProp = (key, value) => (item) => {
  item[key] = value;
  return item;
};
