/* eslint-disable no-console */
import {
  prepareError,
  findItemNested,
  getParent,
  getOrgId,
  logger
} from '~/utils/helpers';

export const state = () => ({
  currentLoc: {
    id: null,
    type: 'node',
    attributes: {
      name: ''
    }
  },
  locList: []
});

export const getters = {
  getCurrent: (s) => s.currentLoc,
  getList: (s) => s.locList
};

export const mutations = {
  setCurrent(state, currentLoc) {
    if (
      Object.keys(currentLoc).length === 0 &&
      currentLoc.constructor === Object
    ) {
      // Set default values
      state.currentLoc = {
        id: null,
        type: '',
        attributes: {
          name: ''
        }
      };
    } else {
      state.currentLoc = currentLoc;
    }
    logger('<[ STORE ]>', 'CURRENT LOC', state.currentLoc.id);
  },

  setList(state, locList) {
    state.locList = locList;
  }
};

export const actions = {
  // Load whole list of locations
  loadList({ commit, $config }, orgId) {
    const depth = 0;
    const include = 'devices,devstate';
    const filter = '';

    this.loading = true;
    return this.$lisaAPI.location
      .list(orgId, depth, include, filter)
      .then((res) => {
        if (res.status === 'ok') {
          const list = res.data;
          logger('<[ STORE ]>', 'LOAD LOC LIST', list);
          commit('setList', list);
        } else {
          console.error('Store/Location/loadList', res.errors);
          commit('setList', []);
          commit(
            'setError',
            prepareError('Store/Location', 'location.errorMsg.failGetLoc'),
            { root: true }
          );
        }
      })
      .finally(() => (this.loading = false));
  },

  // Load a branch of location tree filtered by node id
  filter({ commit, $config }, { orgId, depth, include, filter }) {
    this.loading = true;

    return this.$lisaAPI.location
      .list(orgId, depth, include, filter)
      .then((res) => {
        if (res.status === 'ok') {
          const loc = res.data[0];
          logger('<[ STORE ]>', 'FILTER', loc);
          commit('setCurrent', loc);
        } else {
          console.error('Store/Location/filter', res.errors);
          commit('setCurrent', {});
          commit(
            'setError',
            prepareError('Store/Location', 'location.errorMsg.failGetLoc'),
            { root: true }
          );
        }
      })
      .finally(() => (this.loading = false));
  },

  // Commit setCurrent mutation to set current node
  current({ commit, $config }, node) {
    commit('setCurrent', node);
  },

  // Find node by id in the list of locations
  find({ dispatch, commit, getters, $config }, id) {
    const node = findItemNested(getters.getList, id, 'children');
    if (node) {
      logger('<[ STORE ]>', 'FOUND NODE', node);
      if (getters.getCurrent.id !== node.id)
        return dispatch('current', node).then(() => {
          dispatch('acl/filter', node.id, { root: true });
        });
    } else {
      logger('<[ STORE ]>', 'FOUND', 'NOTHING');
    }
  },

  // Location info
  info({ commit, $config }, locId) {
    const orgId = getOrgId(locId);
    return this.$lisaAPI.location
      .info(orgId, locId)
      .then((res) => {
        if (res.status === 'ok') {
          const loc = res.data[0];
          logger('<[ STORE ]>', 'LOCATION INFO', loc.id);
          commit('setCurrent', loc);
        } else {
          console.error('Store/Location/getLoc', res.error);
          commit(
            'setError',
            prepareError('Store/Location', 'location.errorMsg.failGetLoc'),
            { root: true }
          );
        }
      })
      .finally(() => (this.loading = false));
  },

  // Create a new location
  create({ dispatch, commit, $config }, { nodeId, name }) {
    const orgId = getOrgId(nodeId);
    let loc = {};
    return this.$lisaAPI.location
      .create(orgId, nodeId, name)
      .then((res) => {
        if (res.status === 'ok') {
          if (res.data) {
            loc = res.data[0];
            logger('<[ STORE ]>', 'CREATE LOC', loc.id);
          }
        } else {
          console.error('Store/Location/create', res.error);
          commit(
            'setError',
            prepareError('Store/Location', 'location.errorMsg.failAddLoc'),
            { root: true }
          );
        }
      })
      .then(() => {
        dispatch('loadList', orgId).then(() => {
          dispatch('find', loc ? loc.id : nodeId);
        });
      })
      .finally(() => (this.loading = false));
  },

  // Update a location
  update({ dispatch, commit, $config }, { nodeId, name }) {
    const orgId = getOrgId(nodeId);
    return this.$lisaAPI.location
      .update(orgId, nodeId, name)
      .then((res) => {
        if (res.status === 'ok') {
          logger('<[ STORE ]>', 'UPDATE LOC', nodeId);
        } else {
          console.error('Store/Location/update', res.error);
          commit(
            'setError',
            prepareError('Store/Location', 'location.errorMsg.failUpdLoc'),
            { root: true }
          );
        }
      })
      .then(() => {
        // Update list and after start to find location by id
        dispatch('loadList', orgId).then(() => {
          dispatch('find', nodeId);
        });
      })
      .finally(() => (this.loading = false));
  },

  // Delete location
  delete({ dispatch, commit, $config }, locId) {
    const orgId = getOrgId(locId);
    const parentId = getParent(locId);
    return this.$lisaAPI.location
      .delete(orgId, locId)
      .then((res) => {
        if (res.status === 'ok') {
          logger('<[ STORE ]>', 'DELETE LOC', locId);
        } else {
          console.error('Store/Location/delete', res.error);
          commit(
            'setError',
            prepareError('Store/Location', 'location.errorMsg.failDelLoc'),
            { root: true }
          );
        }
      })
      .then(() =>
        // Update list and after start to find location by id
        dispatch('loadList', orgId).then(() => {
          dispatch('find', parentId);
        })
      )
      .finally(() => (this.loading = false));
  }
};
