
/* eslint-disable no-console */

import { mapGetters, mapActions, mapMutations } from 'vuex';
import { getParent, logger } from '~/utils/helpers';

export default {
  name: 'NodeTree',

  data() {
    return {
      open: [],
      active: [],
      previous: [],
      search: '',
      lastOrg: null,
      lastLoc: null
    };
  },

  computed: {
    ...mapGetters({
      user: 'getIdentity',
      orgList: 'organization/getList',
      currentOrg: 'organization/getCurrent',
      locList: 'location/getList',
      currentLoc: 'location/getCurrent',
      isShared: 'acl/isNodeShared'
    }),

    currentPage() {
      return this.$route.path;
    },

    isActive() {
      return this.currentPage === '/node' || this.currentPage === '/device';
    },

    filter() {
      return (item, search) => {
        const keys = ['name', 'id'];
        return keys.some((key) =>
          item[key].toLowerCase().includes(search.toLowerCase())
        );
      };
    }
  },

  watch: {
    open() {
      this.saveTreeOpen();
    },

    currentPage() {
      // logger(':{ WATCH }:', 'currentPage', this.currentPage);
    },

    isActive(value) {
      if (!value) this.active = [];
    },

    active() {
      if (!this.isActive && !this.active.length) return;

      this.findLocById(this.active[0]);

      if (this.currentLoc.type === 'node')
        this.$router.push(this.localePath('node'));
      else if (this.currentLoc.type === 'device')
        this.$router.push(this.localePath('device'));
      else this.$router.push(this.localePath('/'));
    },

    orgList() {
      if (this.orgList.length === 0) {
        logger(':{ WATCH }:', 'ORG LIST', 'EMPTY');
        this.setCurrentLoc({});
        this.setLocList([]);
      }
    },

    locList() {
      // logger(':{ WATCH }:', 'LOC LIST', this.locList);
      this.loadTreePick();
      this.loadTreeOpen();
    },

    currentOrg() {
      if (this.currentOrg.id === this.lastOrg?.id) return;
      const orgId = this.currentOrg.id;

      if (orgId) {
        this.loadACL(orgId);
        this.loadTree(orgId);
        this.lastOrg = this.currentOrg;
      }
    },

    currentLoc() {
      if (!this.currentLoc.id) return;
      if (this.currentLoc.id === this.lastLoc?.id) return;
      const locId = this.currentLoc.id;
      // logger(':{ WATCH }:', 'CURRENT LOC', locId);

      this.$set(this.active, 0, locId);

      this.saveTreePick();
      this.openTree(locId);

      if (locId) this.lastLoc = this.currentLoc;
    }
  },

  mounted() {
    console.log('* * * * * *    TREE MOUNTED    * * * * * *');
  },

  methods: {
    ...mapActions({
      loadLocList: 'location/loadList',
      findLocById: 'location/find',
      infoLocById: 'location/info',
      filterById: 'location/filter',
      loadACLList: 'acl/list'
    }),
    ...mapMutations({
      setLocList: 'location/setList',
      setCurrentLoc: 'location/setCurrent'
    }),

    loadTree(orgId) {
      this.loadLocList(orgId);
    },

    loadACL(nodeId) {
      this.loadACLList(nodeId);
    },

    openTree(id) {
      const parent = getParent(id);
      if (parent === '') return;
      if (!this.open.find((el) => el === parent)) this.open.push(parent);
      this.openTree(parent);
    },

    setActive(next) {
      if (!this.isActive) return;

      if (this.previous[0] !== this.lastLoc.id)
        this.previous[0] = this.lastLoc.id;

      if (this.active.length && this.active[0] === next[0]) {
        this.previous = [...this.active];
      } else {
        this.active = [...this.previous];
      }
    },

    // Збереження конфігурації дерева в localStorage
    saveTreeOpen() {
      if (!this.currentOrg.id || !this.active.length) return;
      const nameOpen = `${this.user.ID}.${this.currentOrg.id}.open`;
      const stringOpen = JSON.stringify(this.open);
      localStorage.setItem(nameOpen, stringOpen);
      // logger('>> >> >> >>', 'SAVE OPEN', stringOpen);
    },
    // Завантаження конфігурації дерева з localStorage
    loadTreeOpen() {
      if (!this.currentOrg.id) return;
      const nameOpen = `${this.user.ID}.${this.currentOrg.id}.open`;
      const open = localStorage.getItem(nameOpen);
      if (open) {
        try {
          const parsed = JSON.parse(open);
          this.open = parsed;
          // logger('<< << << <<', 'LOAD OPEN', parsed);
        } catch (e) {
          localStorage.removeItem(nameOpen);
        }
      }
    },

    // Збереження обраного вузла дерева в localStorage
    saveTreePick() {
      if (!this.currentOrg.id) return;
      const namePick = `${this.user.ID}.${this.currentOrg.id}.pick`;
      const arrayPick = [this.currentLoc.id];
      const stringPick = JSON.stringify(arrayPick);
      localStorage.setItem(namePick, stringPick);
      // logger('> > > > > >', 'SAVE PICK', stringPick);
    },
    // Завантаження обраного вузла дерева з localStorage
    loadTreePick() {
      if (!this.currentOrg.id) return;
      const namePick = `${this.user.ID}.${this.currentOrg.id}.pick`;
      const pick = localStorage.getItem(namePick);
      if (pick) {
        try {
          const parsed = JSON.parse(pick);
          // logger('< < < < < <', 'LOAD PICK', parsed);
          const [node] = parsed;
          if (node !== this.lastLoc?.id) {
            this.findLocById(node);
          }
        } catch (e) {
          localStorage.removeItem(namePick);
        }
      } else {
        this.setCurrentLoc(this.locList[0]);
      }
    }
  }
};
