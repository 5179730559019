import moment from 'moment'

import 'moment/locale/en-gb'
import 'moment/locale/uk'
import 'moment/locale/ru'

moment.locale('en-gb')

export default (ctx, inject) => {
  ctx.$moment = moment
  inject('moment', moment)
}
