
import { getHWID, getIcon, isVirt } from '~/utils/helpers';

export default {
  name: 'NodeIcon',

  props: {
    item: {
      type: Object,
      default: () => {}
    },
    tip: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {};
  },

  computed: {
    isTip() {
      return this.tip && this.item?.type === 'device';
    },

    status() {
      const i = this.item;
      if (i?.type === 'device') {
        if (i?.state === 1) {
          if (isVirt(getHWID(i?.id))) return 'VIRTUAL';
          else return 'ONLINE';
        }
        if (i?.state === -1) return 'UNKNOWN';
      }
      return 'OFFLINE';
    },

    tooltip() {
      return this.$te(`device.state.${this.status}`)
        ? this.$t(`device.state.${this.status}`)
        : this.status;
    }
  },

  methods: {
    getIcon() {
      return getIcon(this.item);
    },

    getColor() {
      if (this.status === 'VIRTUAL') return '#3f50b5';
      if (this.status === 'ONLINE') return '#00c853';
      if (this.status === 'UNKNOWN') return '#bdbdbd';
      return '';
    }
  }
};
