
export default {
  name: 'HintIcon',

  props: {
    icon: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    iconProps: {
      type: Object,
      default: () => {}
    },
    iconClasses: {
      type: String,
      default: ''
    },
    hintProps: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {};
  }
};
