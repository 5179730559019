/* eslint-disable no-console */
import { prepareError, logger } from '~/utils/helpers';

export const state = () => ({
  currentOrg: {
    id: null,
    type: '',
    attributes: {
      type: '',
      name: '',
      description: '',
      avatar: null
    }
  },
  orgsList: [],
  orgsOwnerList: []
});

export const getters = {
  getCurrent: (s) => s.currentOrg,
  getList: (s) => s.orgsList,
  getOwnerList: (s) => s.orgsOwnerList,
  getOrgOwnerById: (s) => (id) => {
    return s.orgsOwnerList.find((el) => el.id === id);
  }
};

export const mutations = {
  setCurrent(state, currentOrg) {
    if (!currentOrg) return;
    if (
      Object.keys(currentOrg).length === 0 &&
      currentOrg.constructor === Object
    ) {
      // Set default values
      state.currentOrg = {
        id: null,
        type: '',
        attributes: {
          type: '',
          name: '',
          description: '',
          avatar: ''
        }
      };
    } else {
      state.currentOrg = currentOrg;
    }
    logger('<[ STORE ]>', 'CURRENT ORG', currentOrg.id);
  },

  setList(state, orgsList) {
    state.orgsList = orgsList;
    logger('<[ STORE ]>', 'SET ORG LIST', orgsList);
  },

  setOwnerList(state, orgsOwnerList) {
    state.orgsOwnerList = orgsOwnerList;
    // logger('<[ STORE ]>', 'SET OWNERS', orgsOwnerList);
  }
};

export const actions = {
  // Set current organisation by default first item in list getters.getList[0]
  setCurrentByDefault({ commit, getters, dispatch, $config }) {
    let currentOrg = {};
    // if list isn't empty, then set first element as default
    // if (getters.getList.length > 0 && getters.getCurrent.id === null) {
    if (getters.getList && !getters.getCurrent.id) {
      currentOrg = getters.getList[0];
    } else {
      // loading current organization from updated list
      currentOrg = getters.getList.find(
        (item) => item.id === getters.getCurrent.id
      );
    }
    logger('<[ STORE ]>', 'DEFAULT ORG', currentOrg?.id);
    commit('setCurrent', currentOrg);
  },

  // Load groups list by API and prepare list for treeView
  loadList({ commit, getters, dispatch, $config }) {
    return this.$lisaAPI.org
      .list()
      .then((res) => {
        if (res.status === 'ok') {
          // Check array with "orgs" and add owner's info to object "relationships.owner" from "included"
          if (res.data.length !== 0 && Object.keys(res).includes('included')) {
            commit('setOwnerList', res.included);
            for (const item of res.data) {
              if (Object.keys(item).includes('relationships')) {
                item.relationships.owner = getters.getOrgOwnerById(
                  item.relationships.owner.data.id
                );
              } else {
                // Add empty object for View
                item.relationships = {
                  owner: {
                    id: 0,
                    attributes: {
                      avatar: '',
                      email: '',
                      firstname: '',
                      lastname: '',
                      nickname: ''
                    }
                  }
                };
              }
            }
          }
          logger('<[ STORE ]>', 'LOAD ORG LIST', res.data);

          // Save lists and set current org by default
          commit('setList', res.data);
          // dispatch('setCurrentByDefault');
        } else {
          commit('setList', []);
          commit(
            'setError',
            prepareError('Store/Organization', 'orgs.errorMsg.failGetOrgs'),
            { root: true }
          );
        }
      })
      .finally(() => (this.loading = false));
  },

  // Create a new group
  create({ dispatch, commit, $config }, org) {
    return this.$lisaAPI.org
      .create(org.type, org.name, org.description, org.avatar)
      .then((res) => {
        if (res.status === 'ok') {
          if (res.data) {
            logger('<[ STORE ]>', 'CREATE ORG', res.data[0].id);
          }
        } else {
          commit(
            'setError',
            prepareError('Store/Organization', 'orgs.errorMsg.failAddOrg'),
            { root: true }
          );
        }
      })
      .then(() => dispatch('loadList'))
      .finally(() => (this.loading = false));
  },

  // Update a group
  update({ dispatch, commit, getters, $config }, org) {
    return this.$lisaAPI.org
      .update(org.id, org.name, org.description, org.avatar)
      .then((res) => {
        if (res.status === 'ok') {
          logger('<[ STORE ]>', 'UPDATE ORG', org.id);
        } else {
          commit(
            'setError',
            prepareError('Store/Organization', 'orgs.errorMsg.failUpdOrg'),
            { root: true }
          );
        }
      })
      .then(() => dispatch('loadList'))
      .finally(() => (this.loading = false));
  },

  // Delete a group
  delete({ getters, dispatch, commit, $config }, id) {
    return this.$lisaAPI.org
      .delete(id)
      .then((res) => {
        if (res.status === 'ok') {
          logger('<[ STORE ]>', 'DELETE ORG', id);
          if (getters.getCurrent?.id === id && getters.getList)
            if (getters.getList[0].id !== id)
              commit('setCurrent', getters.getList[0]);
            else if (getters.getList.length > 1)
              commit('setCurrent', getters.getList[1]);
        } else {
          commit(
            'setError',
            prepareError('Store/Organization', 'orgs.errorMsg.failDelOrg'),
            { root: true }
          );
        }
      })
      .then(() => dispatch('loadList'))
      .finally(() => (this.loading = false));
  }
};
