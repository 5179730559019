
import { mapGetters, mapMutations } from 'vuex';
export default {
  data: () => ({
    snackbar: false,
    timeout: 3000
  }),
  computed: {
    ...mapGetters({
      alertText: 'error'
    })
  },
  watch: {
    alertText(error) {
      if (error) this.snackbar = true;
    },
    snackbar(showed) {
      if (!showed) this.clearError();
    }
  },
  methods: {
    ...mapMutations({
      clearError: 'clearError'
    })
  }
};
