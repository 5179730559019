/* eslint-disable no-console */
import { prepareError, getOrgId, logger } from '~/utils/helpers';

export const state = () => ({
  currentRule: {},
  rules: [],
  scope: []
});

export const getters = {
  getRules: (s) => s.rules,
  getScope: (s) => s.scope,
  getRule: (s) => s.currentRule,
  getRuleById: (s) => (id) => s.rules.find((el) => el.id === id),
  getRulesByNode: (s) => (node) =>
    s.rules.filter((el) => el.attributes.node === node),
  isNodeShared: (s, getters) => (node) => {
    const r = getters.getRulesByNode(node);
    const isOwner = r.length === 1 && r[0].attributes.level === 'owner';
    return !!r.length && !isOwner;
  }
};

export const mutations = {
  setRules(state, rules) {
    state.rules = rules;
    logger('<[ STORE ]>', 'SET ACL RULES', state.rules);
  },

  setScope(state, scope) {
    state.scope = scope;
    logger('<[ STORE ]>', 'SET ACL SCOPE', state.scope);
  },

  setRule(state, rule) {
    if (Object.keys(rule).length === 0 && rule.constructor === Object) {
      state.currentRule = {
        id: '',
        type: 'acl',
        attributes: {
          created_at: '',
          level: '',
          node: '',
          org_id: 0,
          user_id: 0
        },
        user: {
          avatar: '',
          email: '',
          firstname: '',
          lastname: '',
          nickname: ''
        }
      };
    } else {
      state.currentRule = rule;
    }
    logger('<[ STORE ]>', 'CURR ACL RULE', state.currentRule.id);
  }
};

export const actions = {
  // Create an access rule
  create({ dispatch, commit, $config }, { node, user, level }) {
    const orgId = getOrgId(node);
    this.loading = true;
    return this.$lisaAPI.acl
      .create(orgId, +user, node, level)
      .then((res) => {
        if (res.status === 'ok') {
          if (res.data) {
            const rules = res.data;
            logger('<[ STORE ]>', 'ADD ACL RULE', rules);
          }
        } else {
          console.error('Store/ACL/create', res.error);
          commit(
            'setError',
            prepareError('Store/ACL', 'acl.errorMsg.failAddACL'),
            { root: true }
          );
        }
      })
      .then(() => {
        dispatch('list', orgId).then(() => {
          dispatch('filter', node);
        });
      })
      .finally(() => (this.loading = false));
  },

  // Delete an access rule
  delete({ dispatch, commit, $config }, { node, aclId }) {
    const orgId = getOrgId(node);
    this.loading = true;
    return this.$lisaAPI.acl
      .delete(orgId, aclId)
      .then((res) => {
        if (res.status === 'ok') {
          logger('<[ STORE ]>', 'DEL ACL RULE', aclId);
        } else {
          console.error('Store/ACL/delete', res.error);
          commit(
            'setError',
            prepareError('Store/ACL', 'acl.errorMsg.failDelACL'),
            { root: true }
          );
        }
      })
      .then(() => dispatch('list', orgId))
      .then(() => dispatch('filter', node))
      .finally(() => (this.loading = false));
  },

  // Get the list of access rules
  list({ dispatch, commit, $config }, node) {
    const orgId = getOrgId(node);
    const include = 'userinfo,children';
    this.loading = true;
    return this.$lisaAPI.acl
      .list(orgId, node, include)
      .then((res) => {
        if (res.status === 'ok') {
          if (res.meta.count > 0) {
            const rules = res.data;
            const users = res.included;
            rules.forEach((el) => {
              const user = users.find((u) => +u.id === +el.attributes.user_id);
              el.user = user.attributes;
            });
            logger('<[ STORE ]>', 'LOAD ACL LIST', rules);
            commit('setRules', rules);
          } else {
            commit('setRules', []);
          }
        } else {
          console.error('Store/ACL/list', res.error);
          commit(
            'setError',
            prepareError('Store/ACL', 'acl.errorMsg.failLstACL'),
            { root: true }
          );
        }
      })
      .then(() => dispatch('filter', node))
      .finally(() => (this.loading = false));
  },

  // Filter access rules by node id in the list of access rules
  filter({ dispatch, commit, getters, $config }, nodeId) {
    if (!getters.getRules || !getters.getRules.length)
      return commit('setScope', []);

    const scope = getters.getRulesByNode(nodeId);
    if (scope.length) {
      // logger('<[ STORE ]>', `#${nodeId}`, scope.length);
      return commit('setScope', scope);
    } else {
      // logger('<[ STORE ]>', 'FOUND', 'NOTHING');
      return commit('setScope', []);
    }
  }
};
