/* eslint-disable no-console */
import {
  prepareError,
  getParent,
  getOrgId,
  getHWID,
  isVirt,
  logger
} from '~/utils/helpers';

export const state = () => ({
  currentDev: {
    id: null,
    type: 'device',
    attributes: {
      hwid: null,
      config: null,
      status: null,
      settings: [],
      errors: [],
      lines: [],
      state: '',
      node: '',
      name: ''
    }
  },
  currentLine: {
    alias: '',
    topic: '',
    name: '',
    desc: '',
    dir: '',
    type: '',
    unit: '',
    value: '',
    range: false
  },
  history: {},
  settings: [],
  lineTypes: []
});

export const getters = {
  getDevice: (s) => s.currentDev,
  getLine: (s) => s.currentLine,
  getHistory: (s) => s.history,
  getSettings: (s) => s.settings,
  getLineTypes: (s) => s.lineTypes,
  getLineId: (s) => s.currentDev.attributes.hwid + '/' + s.currentLine.topic,
  getLineName: (s) =>
    s.currentLine.alias ? s.currentLine.alias : s.currentLine.name,
  getLineById: (s) => (id) =>
    s.currentDev.attributes.lines.find((el) => el.topic === id),
  isEnabled: (s) => s.currentDev.attributes.state === 'on',
  isVirtual: (s) => isVirt(s.currentDev.attributes.hwid)
};

export const mutations = {
  setDevice(state, device) {
    if (Object.keys(device).length === 0 && device.constructor === Object) {
      state.currentDev = {
        id: null,
        type: 'device',
        attributes: {
          hwid: null,
          config: null,
          status: null,
          settings: [],
          errors: [],
          lines: [],
          state: '',
          node: '',
          name: ''
        }
      };
    } else {
      state.currentDev = device;
    }
    logger('<[ STORE ]>', 'CURRENT DEV', state.currentDev.id);
  },

  setLine(state, line) {
    if (Object.keys(line).length === 0 && line.constructor === Object) {
      state.currentLine = {
        desc: '',
        dir: '',
        name: '',
        type: '',
        unit: '',
        value: ''
      };
    } else {
      state.currentLine = line;
    }
    logger('<[ STORE ]>', 'CURRENT LINE', state.currentLine.name);
  },

  setValue(state, value) {
    state.currentLine.value = value;
    logger('<[ STORE ]>', 'SET VALUE', state.currentLine.value);
  },

  setHistory(state, history) {
    state.history = history;
    // logger('<[ STORE ]>', 'SET HISTORY', state.history);
  },

  setSettings(state, settings) {
    state.settings = settings;
    logger('<[ STORE ]>', 'SET SETTINGS', state.settings);
  },

  setLineTypes(state, types) {
    state.lineTypes = types;
    logger('<[ STORE ]>', 'SET LINE TYPES', state.lineTypes);
  }
};

export const actions = {
  // Create a new device
  create({ dispatch, commit, $config }, { node, hwid, name }) {
    const orgId = getOrgId(node);
    let dev = {};
    this.loading = true;
    return this.$lisaAPI.device
      .create(orgId, hwid, node, name)
      .then((res) => {
        if (res.status === 'ok') {
          if (res.data) {
            dev = res.data[0];
            logger('<[ STORE ]>', 'CREATE DEVICE', dev.id);
            commit('setDevice', dev);
          }
        } else {
          console.error('Store/Device/create', res.error);
          commit(
            'setError',
            prepareError('Store/Device', 'devices.errorMsg.failAddDev'),
            { root: true }
          );
        }
      })
      .then(() => {
        dispatch('location/loadList', orgId, { root: true }).then(() => {
          dispatch('location/find', dev ? dev.id : node, { root: true });
        });
      })
      .finally(() => (this.loading = false));
  },

  // Update a device
  update({ dispatch, commit, getters, $config }, { node, name }) {
    const orgId = getOrgId(node);
    const hwid = getHWID(node);
    this.loading = true;
    return this.$lisaAPI.device
      .update(orgId, hwid, name)
      .then((res) => {
        if (res.status === 'ok') {
          logger('<[ STORE ]>', 'UPDATE DEVICE', node);
        } else {
          console.error('Store/Device/update', res.error);
          commit(
            'setError',
            prepareError('Store/Device', 'devices.errorMsg.failUpdDev'),
            { root: true }
          );
        }
      })
      .then(() => {
        dispatch('location/loadList', orgId, { root: true }).then(() => {
          dispatch('location/find', node, { root: true });
        });
      })
      .finally(() => (this.loading = false));
  },

  // Delete a device
  delete({ dispatch, commit, $config }, node) {
    const orgId = getOrgId(node);
    const hwid = getHWID(node);
    const parentId = getParent(node);
    this.loading = true;
    return this.$lisaAPI.device
      .delete(orgId, hwid)
      .then((res) => {
        if (res.status === 'ok') {
          logger('<[ STORE ]>', 'DELETE DEVICE', node);
        } else {
          console.error('Store/Device/delete', res.error);
          commit(
            'setError',
            prepareError('Store/Device', 'devices.errorMsg.failDelDev'),
            { root: true }
          );
        }
      })
      .then(() => {
        dispatch('location/loadList', orgId, { root: true }).then(() => {
          dispatch('location/find', parentId, { root: true });
        });
      })
      .finally(() => (this.loading = false));
  },

  // Create a new virtual device
  createVirtual({ dispatch, commit, $config }, { node, name }) {
    const orgId = getOrgId(node);
    let dev = {};
    this.loading = true;
    return this.$lisaAPI.virtual
      .create(orgId, node, name)
      .then((res) => {
        if (res.status === 'ok') {
          if (res.data) {
            dev = res.data[0];
            logger('<[ STORE ]>', 'CREATE VIRTUAL', dev.id);
            commit('setDevice', dev);
          }
        } else {
          console.error('Store/Device/createVirtual', res.error);
          commit(
            'setError',
            prepareError('Store/Device', 'devices.errorMsg.failAddVirt'),
            { root: true }
          );
        }
      })
      .then(() => {
        dispatch('location/loadList', orgId, { root: true }).then(() => {
          dispatch('location/find', dev ? dev.id : node, { root: true });
        });
      })
      .finally(() => (this.loading = false));
  },

  // Load a list of line types for virtual devices
  loadLineTypes({ dispatch, commit, $config }) {
    this.loading = true;
    return this.$lisaAPI.virtual
      .getTypes()
      .then((res) => {
        if (res.status === 'ok') {
          if (res.data) {
            logger('<[ STORE ]>', 'LOAD LINE TYPES', res.data);
            commit('setLineTypes', res.data);
          }
        } else {
          console.error('Store/Device/loadLineTypes', res.error);
          commit(
            'setError',
            prepareError('Store/Device', 'devices.errorMsg.failLoadTypes')
          );
        }
      })
      .finally(() => (this.loading = false));
  },

  // Append a line to a virtual device
  appendLine({ dispatch, commit, $config }, { node, type, name, desc }) {
    const orgId = getOrgId(node);
    const hwid = getHWID(node);
    let dev = {};
    this.loading = true;
    return this.$lisaAPI.virtual
      .addLine(orgId, hwid, type, name, desc)
      .then((res) => {
        if (res.status === 'ok') {
          if (res.data) {
            dev = res.data[0];
            logger('<[ STORE ]>', 'APPEND LINE', dev.id);
          } else {
            logger('<[ STORE ]>', '?! APPEND LINE', res.data);
          }
        } else {
          console.error('Store/Device/appendLine', res.error);
          commit(
            'setError',
            prepareError('Store/Device', 'devices.errorMsg.failAddLine'),
            { root: true }
          );
        }
      })
      .then(() => dispatch('status', node))
      .finally(() => (this.loading = false));
  },

  // Remove a line from a virtual device
  removeLine({ dispatch, commit, $config }, path) {
    const orgId = getOrgId(path);
    const devId = getParent(path);
    this.loading = true;
    return this.$lisaAPI.virtual
      .delLine(orgId, path)
      .then((res) => {
        if (res.status === 'ok') {
          logger('<[ STORE ]>', 'REMOVE LINE', path);
        } else {
          console.error('Store/Device/removeLine', res.error);
          commit(
            'setError',
            prepareError('Store/Device', 'devices.errorMsg.failDelLine'),
            { root: true }
          );
        }
      })
      .then(() => dispatch('status', devId))
      .finally(() => (this.loading = false));
  },

  // Rename a line alias
  alias({ dispatch, commit, getters, $config }, { node, topic, alias }) {
    const orgId = getOrgId(node);
    const hwid = getHWID(node);
    const name = getters.getDevice.attributes.name;
    this.loading = true;
    return this.$lisaAPI.device
      .update(orgId, hwid, name, topic, alias)
      .then((res) => {
        if (res.status === 'ok') {
          logger('<[ STORE ]>', 'RENAME LINE', getters.getLineId);
        } else {
          console.error('Store/Device/alias', res.error);
          commit(
            'setError',
            prepareError('Store/Device', 'devices.errorMsg.failUpdDev'),
            { root: true }
          );
        }
      })
      .then(() => {
        dispatch('status', node).then(() => {
          commit('setLine', getters.getLineById(topic));
        });
      })
      .finally(() => (this.loading = false));
  },

  // Get status of device
  status({ dispatch, commit, getters, $config }, node) {
    const orgId = getOrgId(node);
    const hwid = getHWID(node);
    this.loading = true;
    return this.$lisaAPI.device
      .status(orgId, hwid)
      .then((res) => {
        if (res.status === 'ok') {
          const dev = res.data[0];
          logger('<[ STORE ]>', 'DEVICE STATUS', dev);
          commit('setDevice', dev);
          if (dev.attributes.settings.length) dispatch('loadSettings', node);
          else commit('setSettings', []);
        } else {
          console.error('Store/Device/status', res.error);
          commit(
            'setError',
            prepareError('Store/Device', 'devices.errorMsg.failStaDev'),
            { root: true }
          );
        }
      })
      .finally(() => (this.loading = false));
  },

  // Get history of device's line
  history({ dispatch, commit, $config }, { node, line, from, to }) {
    const orgId = getOrgId(node);
    const lineId = node + '/' + line;
    this.loading = true;
    return this.$lisaAPI.device
      .history(orgId, lineId, from, to)
      .then((res) => {
        if (res.status === 'ok') {
          const history = res.data[0].attributes;
          logger('<[ STORE ]>', 'LINE HISTORY', history);
          commit('setHistory', history);
        } else {
          console.error('Store/Device/history', res.error);
          commit(
            'setError',
            prepareError('Store/Device', 'devices.errorMsg.failHisDev'),
            { root: true }
          );
          commit('setHistory', {});
        }
      })
      .finally(() => (this.loading = false));
  },

  // Get the current value of a line
  loadLine({ dispatch, commit, getters, $config }, path) {
    const orgId = getOrgId(path);
    this.loading = true;
    return this.$lisaAPI.device
      .getLine(orgId, path)
      .then((res) => {
        if (res.status === 'ok') {
          if (res.data) {
            const value = res.data[0].attributes.value;
            logger('<[ STORE ]>', 'LOAD LINE', `${path}: ${value}`);
            commit('setValue', value);
          } else {
            logger('<[ STORE ]>', '?? LOAD LINE', res.data);
          }
        } else {
          console.error('Store/Device/loadLine', res.error);
          commit(
            'setError',
            prepareError('Store/Device', 'devices.errorMsg.failGetValue'),
            { root: true }
          );
        }
      })
      .finally(() => (this.loading = false));
  },

  // Set the current value of a line
  saveLine({ dispatch, commit, $config }, { path, value }) {
    const orgId = getOrgId(path);
    this.loading = true;
    return this.$lisaAPI.device
      .setLine(orgId, path, value)
      .then((res) => {
        if (res.status === 'ok') {
          logger('<[ STORE ]>', 'SAVE LINE', `${path}: ${value}`);
        } else {
          console.error('Store/Device/saveLine', res.error);
          commit(
            'setError',
            prepareError('Store/Device', 'devices.errorMsg.failSetValue'),
            { root: true }
          );
        }
      })
      .then(() => setTimeout(() => dispatch('loadLine', path), 1000))
      .finally(() => (this.loading = false));
  },

  // Get device settings
  loadSettings({ dispatch, commit, getters, $config }, node) {
    if (node !== getters.getDevice.id)
      logger('<[ STORE ]>', '! STRANGE NODE', node);
    if (!getters.getDevice.attributes.settings) {
      logger('<[ STORE ]>', 'SETTINGS', 'ARE MISSING');
      commit('setSettings', []);
      return;
    }

    const orgId = getOrgId(node);
    const hwid = getHWID(node);
    this.loading = true;
    return this.$lisaAPI.device
      .loadSettings(orgId, hwid)
      .then((res) => {
        if (res.status === 'ok') {
          const settings = res.data[0].attributes;
          logger('<[ STORE ]>', 'LOAD SETTINGS', settings);
          commit('setSettings', settings);
        } else {
          console.error('Store/Device/loadSettings', res.error);
          commit(
            'setError',
            prepareError('Store/Device', 'devices.errorMsg.failGetSetting'),
            { root: true }
          );
        }
      })
      .finally(() => (this.loading = false));
  },

  // Set device settings
  saveSettings({ dispatch, commit, $config }, { node, settings }) {
    const orgId = getOrgId(node);
    const hwid = getHWID(node);
    this.loading = true;
    return this.$lisaAPI.device
      .saveSettings(orgId, hwid, settings)
      .then((res) => {
        if (res.status === 'ok') {
          logger('<[ STORE ]>', 'SAVE SETTINGS', settings);
        } else {
          console.error('Store/Device/saveSettings', res.error);
          commit(
            'setError',
            prepareError('Store/Device', 'devices.errorMsg.failSetSetting'),
            { root: true }
          );
        }
      })
      .then(() => dispatch('loadSettings', node))
      .finally(() => (this.loading = false));
  }
};
